import Storage from "good-storage"
import testUrl from "./apiTest"
import proUrl from "./apiPro"
import { getUserOrgan, getToken } from '@/utils/dataStorage'

export const USER_INFO = "user_info"
export const TOKEN_KEY = "auth_token"
//数据字典
export const DATA_DICT = "dataDictKey"
//用户菜单
export const USER_MENU = "userMenu"
//用户按钮
export const USER_BTNS = "userbtns"
//用户oragn
export const USER_ORGAN = "userOrgan"
//应用code码
export const APP_CODE = "appCode"
//商城token信息
export const SHOP_TOKEN = "shopToken"
//b端商城token信息
export const SHOP_TOKEN_B = "shopTokenb"
//门户token
export const PORTAL_TOKEN_KEY = "portalToken"
//实体立标
export const BENTITY_LIST = 'bentity_list'
//资源查看的地址 直接加返回的md5
export const fileLookUrl = function () {
    return Storage.session.get("staticreSources")
}
//老的地址，共用
export const fileUrl = "https://file-server.laoniuche.cn" //生产

export const API_SERVER_HOST = process.env.REACT_APP_MODE === "pro" ? proUrl.API_SERVER_HOST : testUrl.API_SERVER_HOST
export const PORTAL_API_SERVER_HOST = process.env.REACT_APP_MODE === "pro" ? proUrl.PORTAL_API_SERVER_HOST : testUrl.PORTAL_API_SERVER_HOST
export const API_SERVER_PORT = process.env.REACT_APP_MODE === "pro" ? proUrl.API_SERVER_PORT : testUrl.API_SERVER_PORT
export const fileUploadUrl = process.env.REACT_APP_MODE === "pro" ? proUrl.fileUploadUrl : testUrl.fileUploadUrl
export const WS_API_SERVER = process.env.REACT_APP_MODE === "pro" ? proUrl.WS_API_SERVER : testUrl.WS_API_SERVER
export const SHOP_API_SERVE = process.env.REACT_APP_MODE === "pro" ? proUrl.SHOP_API_SERVE : testUrl.SHOP_API_SERVE
export const SHOP_B_API_SERVE = process.env.REACT_APP_MODE === "pro" ? proUrl.SHOP_B_API_SERVE : testUrl.SHOP_B_API_SERVE
export const SHOP_API_FILE_SERVE = process.env.REACT_APP_MODE === "pro" ? proUrl.SHOP_API_FILE_SERVE : testUrl.SHOP_API_FILE_SERVE
export const TDMS_API_SERVE = process.env.REACT_APP_MODE === "pro" ? proUrl.TDMS_API_SERVE : testUrl.TDMS_API_SERVE
export const debugMode = process.env.REACT_APP_MODE === "pro" ? proUrl.debugMode : testUrl.debugMode


//根据环境变量自动获取图片查看地址
export const getBaseImgLookUrl = (md5) => {
    //自动选择环境
    return `${PORTAL_API_SERVER_HOST}fsrv/fetch/${md5}?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseImgUploadUrl = () => {
    //自动选择环境
    return `${PORTAL_API_SERVER_HOST}fsrv/upload_file?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseUploadData = () => {
    return {
        appCode: 'ci',
        fileMode: 0,
    }
}