import React, { useEffect, useContext } from 'react'
import { MyContext } from '@/store/myContext'
import { useHomeMapModel } from './homeMapModel'
import { Switch, } from 'antd'
import fullS from "../../assets/images/full-screen.png"
import fullSC from "../../assets/images/full-screen-cancel.png"
import VehicleList from "./vehicleList"
import "./index.less"

export default () => {
    const { dispatch, state } = useContext(MyContext)
    const { showFull, setShowFull, handleAutoRefresh, initMap, getVehicles, createWebSocket, vehicleList, markers, updateMakers,mapCenter, mapRef, openVehicleWindowInfo } = useHomeMapModel()

    //默认加载待工接单 和进度提醒数据
    useEffect(() => {
        if (state.activeMenu === "homeIndex") {
            init()
        }
    }, [state])

    const init = () => {
        if(window && window.TMap){
            initMap();
            getVehicles();
        }else{
            setTimeout(() => {
                init()
            }, 100);
        }
    }

    //初始化ws
    useEffect(() => {
        createWebSocket()
    },[])

    //更新marker
    useEffect(() => {
        updateMakers(markers)
    },[markers])


    useEffect(() => {
        const {longitude, latitude} = mapCenter
        if(longitude && latitude && mapRef.current && mapRef.current.setCenter){
            mapRef.current.setCenter(new window.TMap.LatLng(latitude, longitude))
        }
    },[mapCenter])

    return (
        <>
            {state.activeMenu === 'homeIndex' && (
                <div className={`home-container ${showFull ? 'full-screen' : ''} `}>
                    <div
                        className="full-screen-btn"
                        onClick={() => {
                            setShowFull(!showFull)
                        }}
                    >
                        <img src={showFull ? fullS : fullSC} />
                    </div>
                    <div id="map-box" style={{width:"100%", height:"100%"}}></div>
                    <div className="home-top-box">
                        <div className="top-left-box">
                            <Switch
                                className="switch"
                                onChange={handleAutoRefresh}
                                checkedChildren="开启自动刷新"
                                unCheckedChildren="关闭自动刷新"
                                size="default"
                            />
                            <VehicleList
                                vehicleList={vehicleList}
                                openVehicleWindowInfo={openVehicleWindowInfo}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
