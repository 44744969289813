import React, { useEffect, useState } from "react"
import { Form, Col, Row, Select, message, Modal, Spin, Input } from "antd"
import { getTeamList, getTeamMemberList } from "@/service/teamService"
import { organPage } from "@/service/orgService"
import { dispatchOrder2, editTeamUser } from "@/service/ordersService"
import debounce from "lodash/debounce"
import { getUserInfo, getPcodeDict, getUserOrgan } from "@/utils/dataStorage"
import { getBussinessEntityList } from "@/service/dataService"
import { getCustomerOne } from "@/service/customerService"
export default (props) => {
    const { rowInfo, visible, setVisible, onSuccess, showOrderInfo } = props
    const [form] = Form.useForm()
    const [organType, setOrganType] = useState(0)
    //机构列表
    const [org, setOrg] = useState([])
    //团队列表
    const [teamInfo, setTeamInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })

    const [entityData, setEntityData] = useState({
        //所属实体信息
        data: [],
        fetching: false,
    })

    const handleChangeOrg = () => {
        form.resetFields(["teamId", "majorUserId", "teamUserIds"])
    }

    //成员列表
    const [memberInfo, setMemberInfo] = useState({
        id: "", //列表
        data: [],
        fetching: false,
    })
    //选中添加的成员
    const [mainMemberList, setMainMemberList] = useState([])

    useEffect(() => {
        organPage({
            searchItem: { showPlatformOrgan: true },
            pageNum: 1,
            pageSize: 100,
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            console.log(res.retData.list)
            setOrg(res.retData.list)
        })
        const { organType } = getUserOrgan()
        setOrganType(organType)
    }, [])

    useEffect(() => {
        if (visible) {
            form.resetFields()
            let { organId } = getUserInfo()
            let defaltForm = { organId }
            if (visible === "modify") {
                defaltForm.teamId = rowInfo.teamId
                setTeamInfo({
                    ...teamInfo,
                    data: [
                        {
                            text: rowInfo.teamName,
                            value: rowInfo.teamId,
                        },
                    ],
                    fetching: false,
                })
            }
            defaltForm.serviceMethod = rowInfo.serviceMethod
            defaltForm.serviceType = rowInfo.serviceType
            defaltForm.orderType = rowInfo.orderType

            if (visible === "paidan") {
                defaltForm.serviceMethod = rowInfo.serviceMethod
                defaltForm.serviceType = rowInfo.serviceType
                defaltForm.orderType = rowInfo.orderType
                getDefaultEntity(rowInfo.customerId)
            }
            form.setFieldsValue(defaltForm)
        }
    }, [visible]) // eslint-disable-line react-hooks/exhaustive-deps

    const getDefaultEntity = (id) => {
        let data = {
            data: [],
            fetching: false,
        }
        if (rowInfo.ciBusinessEntityName && rowInfo.ciBusinessEntityId) {
            form.setFieldsValue({
                ciBusinessEntityName: rowInfo.ciBusinessEntityName,
                ciBusinessEntityId: rowInfo.ciBusinessEntityId,
            })
            data = {
                data: [{ text: rowInfo.ciBusinessEntityName, value: rowInfo.ciBusinessEntityId }],
                fetching: false,
            }
            setEntityData(data)
            return
        }
        getCustomerOne(id).then((res) => {
            if (res && res.retData && res.retData.ciBusinessEntityId && res.retData.ciBusinessEntityName) {
                data = {
                    data: [{ text: res.retData.ciBusinessEntityName, value: res.retData.ciBusinessEntityId }],
                    fetching: false,
                }
                form.setFieldsValue({
                    ciBusinessEntityName: res.retData.ciBusinessEntityName,
                    ciBusinessEntityId: res.retData.ciBusinessEntityId,
                })
            }
            setEntityData(data)
        })
    }

    //团队列表查询
    const teamQuery = (value) => {
        setMemberInfo({
            ...teamInfo,
            data: [],
            fetching: true,
        })
        let organId = form.getFieldValue("organId")
        getTeamList({
            searchItem: { disableFlag: 0, teamName: value, organId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.teamName,
                    value: item.id,
                })
                return arr
            }, [])
            setTeamInfo({
                ...teamInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const teamQuerys = debounce(teamQuery, 1000)
    const handleChangeTeam = () => {
        form.resetFields(["majorUserId", "teamUserIds"])
    }

    //成员列表查询
    const memberQuery = (value) => {
        setMemberInfo({
            ...memberInfo,
            data: [],
            fetching: true,
        })
        let teamId = form.getFieldValue("teamId")
        getTeamMemberList({
            pageNum: 1,
            pageSize: 10000,
            searchItem: { disableFlag: 0, userName: value, teamId },
            orders: [{ column: "createTime", rule: "DESC" }],
        }).then((res) => {
            let results = res.retData.list
            const data = results.reduce((arr, item) => {
                arr.push({
                    text: item.userName,
                    value: item.id,
                })
                return arr
            }, [])
            setMemberInfo({
                ...memberInfo,
                data: data,
                fetching: false,
            })
        })
    }
    //防抖
    const memberQuerys = debounce(memberQuery, 1000)
    const handleChangeMember = (val, option) => {
        let list = option.map((item) => {
            return {
                text: item.children,
                value: item.value,
            }
        })
        setMainMemberList(list)
        let majorUserId = form.getFieldValue("majorUserId")
        if (majorUserId) {
            if (val.indexOf(majorUserId) === -1) {
                form.resetFields(["majorUserId"])
            }
        }
    }

    //表单提交
    const onUserSubmit = () => {
        form.validateFields().then((values) => {
            let data = {
                ...values,
                orderId: rowInfo.id,
            }
            if (visible === "paidan") {
                data.completeOrderInfo = {
                    serviceMethod: values.serviceMethod,
                    serviceType: values.serviceType,
                    orderType: values.orderType,
                    // ciBusinessEntityId: values.ciBusinessEntityId,
                    // ciBusinessEntityName: values.ciBusinessEntityName,
                }
            }
            if (visible === "modify") {
                editTeamUser(data).then((res) => {
                    message.success("维修人员修改成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            } else {
                dispatchOrder2(data).then((res) => {
                    message.success("派单成功")
                    onSuccess && onSuccess()
                    handleCancel()
                })
            }
        })
    }

    const formItemLayout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 20,
        },
    }

    const handleCancel = () => {
        setVisible("")
    }

    const fetchEntity = (value) => {
        setEntityData({
            ...entityData,
            fetching: true,
        })
        let data = {
            searchItem: {
                entityName: value,
            },
            pageNum: 1,
            pageSize: 10,
            orders: [
                {
                    column: "createTime",
                    rule: "DESC",
                },
            ],
        }
        getBussinessEntityList(data).then((res) => {
            let results = res.retData.list
            const dataMap = results.map((team) => ({
                text: team.entityName,
                value: team.id,
            }))
            setEntityData({
                data: dataMap,
                fetching: false,
            })
        })
    }
    //防抖
    const fetchEntitys = debounce(fetchEntity, 1000)
    //选择实体
    const handleChangeEntity = (value, option) => {
        form.setFieldsValue({
            ciBusinessEntityName: value ? option.children : undefined,
        })
    }

    return (
        <>
            <Modal forceRender visible={visible} title="派单" centered={true} onOk={() => onUserSubmit()} onCancel={() => handleCancel()} width={600}>
                <div className="form-details-warp">
                    <Form form={form} {...formItemLayout}>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item label="机构" name="organId" rules={[{ required: true, message: "请选择机构" }]}>
                                    <Select placeholder="请选择机构" style={{ width: "100%" }} onChange={handleChangeOrg} disabled={organType !== 3}>
                                        {org.map((item) => (
                                            <Select.Option value={item.id} key={item.id}>
                                                {item.organName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="团队" name="teamId" rules={[{ required: true, message: "请选择团队" }]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="请选择团队"
                                        notFoundContent={teamInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onChange={handleChangeTeam}
                                        onFocus={() => teamQuery()}
                                        onSearch={teamQuerys}
                                        style={{ width: "100%" }}
                                        disabled={visible === "modify"}
                                    >
                                        {teamInfo.data.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="团队成员" name="teamUserIds" rules={[{ required: true, message: "请选择团队成员" }]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        placeholder="请选择团队成员"
                                        notFoundContent={memberInfo.fetching ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onChange={handleChangeMember}
                                        onFocus={() => memberQuery()}
                                        onSearch={memberQuerys}
                                        style={{ width: "100%" }}
                                    >
                                        {memberInfo.data.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="主维修人" name="majorUserId" rules={[{ required: true, message: "请选择主维修人" }]}>
                                    <Select placeholder="请选择主维修人" style={{ width: "100%" }}>
                                        {mainMemberList.map((item) => (
                                            <Select.Option value={item.value} key={item.value}>
                                                {item.text}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {visible === "paidan" && (
                                <>
                                    <Col span={24}>
                                        <Form.Item
                                            label="服务方式"
                                            name="serviceMethod"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择服务方式",
                                                },
                                            ]}
                                        >
                                            <Select allowClear placeholder="请选择服务方式" style={{ width: "100%" }}>
                                                {getPcodeDict("012").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="故障类型"
                                            name="serviceType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择故障类型",
                                                },
                                            ]}
                                        >
                                            <Select allowClear placeholder="请选择故障类型" style={{ width: "100%" }}>
                                                {getPcodeDict("011").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="工单类型"
                                            name="orderType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "请选择工单类型",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择工单类型" style={{ width: "100%" }}>
                                                {getPcodeDict("014").children.map((item) => (
                                                    <Select.Option value={item.dictCode} key={item.id}>
                                                        {item.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={24}>
                                        <Form.Item label="所属实体" name="ciBusinessEntityId">
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="请选择所属实体队"
                                                notFoundContent={entityData.fetching ? <Spin size="small" /> : null}
                                                optionFilterProp="children"
                                                onChange={handleChangeEntity}
                                                onFocus={() => fetchEntity()}
                                                style={{ width: "100%" }}
                                                onSearch={fetchEntitys}
                                                filterOption={false}
                                            >
                                                {entityData.data.map((item) => (
                                                    <Select.Option value={item.value} key={item.value}>
                                                        {item.text}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item hidden name="ciBusinessEntityName">
                                        <Input />
                                    </Form.Item> */}
                                </>
                            )}
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
