import React, { useState, useEffect, useRef } from 'react'
import { Modal, Button, Input } from 'antd'
import styles from './index.module.less'
const { Search } = Input
export default (props) => {
    const { visible, onCloseMap, saveAddressDetail, defaultInfo, isLook } = props
    const mapRef = useRef({})
    const markerRef = useRef({})
    const infoWindowListRef = useRef([])
    const [suggestObj, setsuggestObj] = useState({}) //储存搜索的内容；
    const [searchList, setsearchList] = useState([]) //搜索栏数据
    const [inputVal, setinputVal] = useState() //搜索栏内容
    const [searchObj, setsearchObj] = useState({})

    const [signAddrList, setSignAddrList] = useState({
        //地图初始信息
        name: '东华门街道南河沿大街69号',
        addr: '北京市东城区东华门街道天安门',
        longitude: 116.397451,
        latitude: 39.909187,
    })

    const TMap = window.TMap
    useEffect(() => {
        if (visible) {
            // 设置初始化
            const map = new TMap.Map(document.getElementById('container'), {
                center: new TMap.LatLng(signAddrList.latitude, signAddrList.longitude),
                zoom: 15,
                noClear: false,
            })
            // 设置搜索
            const searchItem = new TMap.service.Search({ pageSize: 10 })
            const suggest = new TMap.service.Suggestion({
                // 新建一个关键字输入提示类
                pageSize: 10, // 返回结果每页条目数
                // region: 'XX城市', // 限制城市范围
                regionFix: true, // 搜索无结果时是否固定在当前城市
            })
            const marker = new TMap.MultiMarker({
                map: map,
                geometries: [],
            })

            // 点击页面获取地点
            map.on('click', (event) => {
                getChoosedInfo(event.latLng)
            })
            mapRef.current = map
            markerRef.current = marker
            setsuggestObj(suggest)
            setsearchObj(searchItem)

            echo()
        }
    }, [visible])

    const echo = () => {
        const { lat, lng } = defaultInfo
        if (lat && lng && lat !== '0.0' && lng !== '0.0') {
            getChoosedInfo(new TMap.LatLng(lat, lng))
        }
    }

    // 点击搜素时，显示红点
    const onSearchVal = (e) => {
        const keyword = e
        suggestObj.getSuggestions({ keyword: keyword, location: mapRef.current.getCenter() }).then((res) => {
            setsearchList(res.data)
        })
        return
    }
    // 监听input框
    const onChangeInput = (e) => {
        const keyword = e.target.value
        setinputVal(keyword)
        if (!keyword) {
            setsearchList([])
            return
        }
        suggestObj.getSuggestions({ keyword: keyword, location: mapRef.current.getCenter() }).then((res) => {
            setsearchList(res.data)
        })
    }

    // 点击li显示地址
    const suggestItem = (item) => {
        getChoosedInfo(item.location)
        setinputVal(item.title)
        setsearchList([])
    }

    //设置选中的地址信息
    const getChoosedInfo = (location) => {
        //清空infoWindow
        let infoWindowList = [...infoWindowListRef.current]
        infoWindowList.forEach((infoWindow) => {
            infoWindow.close()
        })
        infoWindowList.length = 0

        //设置marker
        markerRef.current.setGeometries([])
        markerRef.current.updateGeometries([
            {
                id: '0', // 点标注数据数组
                position: location,
            },
        ])
        mapRef.current.setCenter(location)
        let geocoder = new TMap.service.Geocoder()
        // 地址逆解析
        geocoder
            .getAddress({ location }) // 将给定的坐标位置转换为地址
            .then((res) => {
                console.log(res)
                const { result } = res
                // 显示搜索到的地址
                let infoWindow = new TMap.InfoWindow({
                    map: mapRef.current,
                    position: result.location,
                    content: `<h3>${result.address}</h3><p>地址：${result.formatted_addresses.standard_address}</p>`,
                    offset: { x: 0, y: -50 },
                })
                infoWindowList.push(infoWindow)
                infoWindowListRef.current = infoWindowList

                setSignAddrList({
                    name: result.address,
                    addr: result.formatted_addresses.standard_address,
                    longitude: result.location.lng,
                    latitude: result.location.lat,
                    result
                })
            })
    }

    const onOK = () => {
        saveAddressDetail(signAddrList)
    }
    return (
        <Modal
            title="位置选择"
            visible={visible}
            width={900}
            onCancel={onCloseMap}
            footer={
                isLook
                    ? null
                    : [
                          <Button key="cancel" onClick={onCloseMap}>
                              取消
                          </Button>,
                          <Button key="ok" type="primary" onClick={onOK}>
                              确定
                          </Button>,
                      ]
            }
        >
            <div className={styles['map-box']}>
                <Search placeholder="请输入地址" enterButton="搜索" size="large" onSearch={onSearchVal} onChange={onChangeInput} value={inputVal} />
                <ul className={styles['suggestionList']}>
                    {searchList.map((item) => {
                        return (
                            <li onClick={() => suggestItem(item)} key={item.id}>
                                {item.title}
                                <span>{item.address}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div id="container" className={styles['container']}></div>
        </Modal>
    )
}
